<template>
  <v-card :loading="isLoading">
    <v-card-text>
      <v-data-table
        :fixed-header="false"
        :height="undefined"
        v-if="clusterId"
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            class="mt-6"
            title="Bağımsız Bölüm Tipleri"
            icon=""
            :add-route="can('edit-cluster') ? '#' : null"
            @click:add.prevent="handleAddClick"
            @clear-filters="$refs.headSearch.reset"
            @reload="loadList"
            @click:edit="handleEditClick"
            :hide-edit="!can('edit-cluster')"
            :edit-enabled="selectedItems.length === 1"
            :search.sync="search.query"
            :show-delete="can('edit-cluster')"
            :delete-enabled="deleteEnabled"
            @click:delete="handleDeleteClick"
          >
            <template v-slot:buttons:append>
              <rs-action
                class="float-end"
                @click="handleTemplateDownloadClick"
                :loading="loading"
              >
                XLSX Şablon İndir
              </rs-action>

              <rs-action
                @click="handleTemplateUploadClick"
                :loading="loading"
                class="float-end"
              >
                XLSX Şablon Yükle
              </rs-action>

              <rs-file
                label="Dosya"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.oasis.opendocument.spreadsheet"
                v-if="!disabled"
                @change="handleFileChange"
                class="d-none"
                id="importInput"
              />
            </template>
          </rs-table-title-bar>

          <HouseTypeForm
            @dismissed="handleFormDismiss"
            @saved="handleFormSaved"
            ref="houseTypeForm"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <a
            href="#"
            class="btn btn-icon btn-sm btn-clean"
            @click.prevent="handleEditClick(item)"
          >
            <i class="menu-icon mdi mdi-pencil"></i>
          </a>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.area="{ item }">
          <span v-if="typeof item.area === 'number'">
            {{ item.area.toLocaleString() }}m²
          </span>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.house_count="{ value }">
          <rs-table-cell-number :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.fee="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>
      </v-data-table>

      <rs-confirm
        ref="confirmDelete"
        :loading="isLoading"
        @confirmed="handleDelete"
        @cancelled="$refs.confirmDelete.hide()"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import ClusterService from "@/core/services/cluster.service";
import { debounce } from "@/core/plugins/debounce";
import { hasDataTable, hasPermissions } from "@/view/mixins";
import HouseTypeForm from "@/view/content/clusters/HouseTypeForm";

export default {
  mixins: [hasDataTable, hasPermissions],
  components: {
    HouseTypeForm,
  },
  props: {
    clusterId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    deleteEnabled() {
      if (this.selectedItems.length !== 1) {
        return false;
      }

      return this.selectedItems[0].house_count === 0;
    },
  },
  watch: {
    options: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.loadList();
      }),
      deep: true,
    },
    clusterId: {
      handler() {
        this.loadList();
      },
    },
  },
  data() {
    return {
      showForm: false,
      formHouseTypeId: null,
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
          hide: () => !this.can("edit-cluster"),
        },
        {
          text: "İsim",
          value: "name",
          searchable: "text",
        },
        {
          text: this.$t("labels.fee_amount"),
          value: "fee",
          searchable: "number",
          align: "right",
        },
        {
          text: this.$t("labels.house_count"),
          value: "house_count",
          searchable: "number",
          align: "right",
        },
        {
          text: this.$t("labels.area"),
          value: "area",
          searchable: "number",
          align: "right",
        },
      ],
    };
  },
  methods: {
    loadList() {
      if (this.isLoading || !this.clusterId) {
        return;
      }

      this.isLoading = true;
      this.list = [];

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      this.$api
        .query(`clusters/${this.clusterId}/house-types`, { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleEditClick(item) {
      if (!item.id) {
        item = this.selectedItems[0];
      }

      this.$refs.houseTypeForm.show(item.id);
    },
    handleFormDismiss() {
      this.showForm = false;
      this.formHouseTypeId = null;
    },
    handleFormSaved() {
      this.loadList();
    },
    handleAddClick() {
      this.$refs.houseTypeForm.show(null, this.clusterId);
    },
    handleDelete() {
      this.isLoading = true;

      this.$api
        .delete(`house-types/${this.selectedItems[0].id}`)
        .then(() => {
          this.$toast.success("Bağımsız bölüm tipi silindi.");
          this.selectedItems = [];
          ClusterService.updateClusterId(this.clusterId);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.$refs.confirmDelete.hide();
          this.isLoading = false;
          this.$nextTick(() => this.loadList());
        });
    },
    handleDeleteClick() {
      this.$refs.confirmDelete.show(
        `${this.selectedItems[0].name} bağımsız bölüm tipini silmek istediğinizden emin misiniz?`
      );
    },
    handleTemplateDownloadClick() {
      if (this.isLoading) return false;
      this.isLoading = true;

      this.$api
        .post(`clusters/${this.clusterId}/import-house-types-template`)
        .then((response) => {
          const anchor = document.createElement("a");
          anchor.href = response.data.data.url;
          anchor.target = "_blank";
          // anchor.download =
          //   response.data.data.title + "." + response.data.data.extension;
          anchor.click();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleTemplateUploadClick() {
      document.querySelector("input#importInput").value = null;
      document.querySelector("input#importInput").click();
    },
    handleFileChange(file) {
      if (!file) {
        return;
      }

      const allowedMimes = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.oasis.opendocument.spreadsheet",
      ];

      if (allowedMimes.indexOf(file.type) === -1) {
        this.$toast.error("Sadece XLSX veya ODS yükleyebilirsiniz.");
        return;
      }

      this.isLoading = true;
      const data = new FormData();
      data.append("file", file);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      this.$api
        .post(`clusters/${this.clusterId}/import-house-types`, data, config)
        .then(() => {
          setTimeout(() => {
            this.loadList();
          });
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadList();
    });
  },
};
</script>
