// TODO: those should be retrieved from server
export const ACCOUNTING_RANGES = [
  {
    id: 1,
    translation_key: "company.accounting_type.1",
  },
  {
    id: 2,
    translation_key: "company.accounting_type.2",
  },
  {
    id: 3,
    translation_key: "company.accounting_type.3",
  },
  {
    id: 4,
    translation_key: "company.accounting_type.4",
  },
  {
    id: 5,
    translation_key: "company.accounting_type.4",
  },
  {
    id: 6,
    translation_key: "company.accounting_type.4",
  },
];
