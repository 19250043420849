<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">TYA İçe Aktarım</template>
    <template slot="body">
      <p>
        Aktarım şablonunu indirmek için
        <a :href="templateDownloadTarget" target="_blank">tıklayın</a>.
      </p>
      <p>
        <strong>ÖNEMLİ:</strong> Aktarımın başarıyla yapılabilmesi için şablon
        formatında değişiklik <strong>yapmamanız</strong> gerekir.
      </p>
      <v-form ref="form" @submit.prevent="handleFormSubmit">
        <v-row dense>
          <v-col sm="12">
            <rs-file
              label="Dosya"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.oasis.opendocument.spreadsheet, application/vnd.ms-excel.sheet.macroEnabled.12"
              :rules="[rules.required]"
              :readonly="disabled"
              :filled="disabled"
              @change="handleFileChange"
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="hide()"
          @submit.prevent="handleFormSubmit"
          hide-submit
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import Vue from "vue";

import hasForm from "@/view/mixins/hasForm";

export default {
  name: "ImportForm",
  mixins: [hasForm],
  computed: {
    templateDownloadTarget() {
      return Vue.axios.defaults.baseURL + "templates/import/cluster";
    },
  },
  data() {
    return {
      file: null,
    };
  },
  methods: {
    show() {
      this.$nextTick(() => {
        this.$refs.sidePanel.show();
      });
    },
    hide() {
      this.formData.file = null;
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return;
      }

      this.isLoading = true;
      const data = new FormData();
      data.append("file", this.file);
      data.append("cluster_id", this.$route.params.id);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      this.$api
        .post(`clusters/${this.$route.params.id}/import`, data, config)
        .then((response) => {
          this.$toast.success("Kaydedildi");
          this.$emit("saved", response.data.data);
          if (event && event.closeOnSave) {
            this.hide();
            return;
          }

          this.loadData(response);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFileChange(file) {
      if (!file) {
        this.file = null;
        return;
      }

      const allowedMimes = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.oasis.opendocument.spreadsheet",
        "application/vnd.ms-excel.sheet.macroEnabled.12",
      ];

      if (allowedMimes.indexOf(file.type) === -1) {
        this.file = null;
        this.$toast.error("Sadece XSLM, XLSX veya ODS yükleyebilirsiniz.");
        return;
      }

      this.file = file;
    },
  },
};
</script>
