<template>
  <v-data-table
    :fixed-header="false"
    :height="undefined"
    v-if="clusterId"
    v-bind="dataTableAttrs"
    :headers="headersShown"
    :items="list"
    :loading="isLoading"
    :options.sync="options"
    :server-items-length="total"
    :items-per-page.sync="itemsPerPage"
    v-model="selectedItems"
    @update:items-per-page="handleItemsPerPageUpdated"
  >
    <template v-slot:top>
      <rs-table-title-bar
        class="mt-6"
        v-if="$refs.headSearch"
        title="Bloklar"
        icon=""
        :add-route="can('edit-cluster') ? '#' : null"
        @click:add.prevent="handleAddClick"
        @clear-filters="$refs.headSearch.reset"
        @reload="loadList"
        @click:edit="handleEditClick"
        :hide-edit="!can('edit-cluster')"
        :edit-enabled="selectedItems.length === 1"
        :search.sync="search.query"
      >
        <template slot="buttons:append">
          <v-btn
            v-if="can('add-house')"
            class="float-end ms-2"
            color="pink"
            outlined
            small
            @click="handleAddHouseClick"
            :disabled="selectedItems.length !== 1"
          >
            BB Ekle
          </v-btn>
        </template>
      </rs-table-title-bar>

      <BlockForm
        @dismissed="handleFormDismiss"
        @saved="handleFormSaved"
        ref="blockForm"
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:body.prepend="{ headers }">
      <rs-table-head-search
        :headers="headers"
        :search.sync="search"
        ref="headSearch"
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.edit="{ item }">
      <a
        href="#"
        class="btn btn-icon btn-sm btn-clean"
        @click.prevent="handleEditClick(item)"
      >
        <i class="menu-icon mdi mdi-pencil"></i>
      </a>
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.house_count="{ item }">
      <span v-if="typeof item.house_count === 'number'">
        {{ item.house_count.toLocaleString() }}
      </span>
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.is_active="{ item }">
      <v-icon v-if="item.is_active" color="success">mdi-check</v-icon>
      <v-icon v-else color="warning">mdi-close</v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { debounce } from "@/core/plugins/debounce";
import { hasDataTable, hasPermissions } from "@/view/mixins";
import BlockForm from "@/view/content/clusters/BlockForm";

export default {
  mixins: [hasDataTable, hasPermissions],
  components: {
    BlockForm,
  },
  props: {
    clusterId: {
      type: Number,
      required: true,
    },
  },
  watch: {
    options: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.loadList();
      }),
      deep: true,
    },
    clusterId: {
      handler() {
        this.loadList();
      },
    },
  },
  data() {
    return {
      showForm: false,
      formBlockId: null,
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
          hide: () => !this.can("edit-cluster"),
        },
        {
          text: this.$t("labels.block_name"),
          value: "name",
          searchable: "text",
        },
        {
          text: this.$t("labels.house_count"),
          value: "house_count",
          searchable: "number",
          align: "right",
        },
        {
          text: this.$t("labels.status"),
          value: "is_active",
          searchable: "isActive",
          align: "center",
        },
      ],
    };
  },
  methods: {
    loadList() {
      if (this.isLoading || !this.clusterId) {
        return;
      }

      this.isLoading = true;
      this.list = [];

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      this.$api
        .query(`clusters/${this.clusterId}/blocks`, { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleEditClick(item) {
      if (!item.id) {
        item = this.selectedItems[0];
      }

      this.$refs.blockForm.show(item.id);
    },
    handleFormDismiss() {
      return;
    },
    handleFormSaved() {
      this.loadList();
    },
    handleAddClick() {
      this.$refs.blockForm.show();
    },
    handleAddHouseClick() {
      if (this.selectedItems.length === 1) {
        this.$router.push({
          name: "definitions.houses.create",
          params: {
            formParams: {
              block_id: this.selectedItems[0].id,
            },
          },
        });
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadList();
    });
  },
};
</script>
